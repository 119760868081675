@import "./project-variables.scss";

html, body {
    font-family: 'Monserrat', sans-serif;
    font-size: 16px;
}
textarea, input {
    font-family: 'Monserrat', sans-serif;
}

.el-dialog__body, .el-popover{
    word-break: normal;
}

button i.fab + span, button i.fas + span {
    padding: 0 0 0 5px;
}

.el-dialog__body{
    padding-top:10px;
    padding-bottom:10px;
}

.el-menu--horizontal>.el-menu-item{
    height:40px;
    line-height:40px;
}
.el-menu.el-menu--horizontal {
    border-bottom: inherit;
}

.el-menu--horizontal>.el-submenu, .el-menu--horizontal>.el-submenu .el-submenu__title{
    height:40px;
    line-height:40px;
}

.el-table .cell{
    word-break: normal;
}




.el-tree-node__content:hover {
    background-color: #FFF9EE;
}
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background-color: #ffe6c5;
}







.el-button{
    text-transform: uppercase;
    font-weight: bold;
    /*color: black;*/
}

.el-button.button-icon-green i{
    color: $--color-success;
}


//.el-pagination.is-background .el-pager li:not(.disabled).active{
//    color: $button-text-color
//}
//
//.el-button--primary.is-disabled, .el-button--primary.is-disabled:hover, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:active {
//    color: $button-disabled-text-color;
//}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

//
//.el-button.el-button--default{
//    background-color: #E4E6EC;
//    color: #999999;
//}
//.el-button.el-button--default:hover {
//    background-color: #FBECE5;
//    border-color: #FBECE5;
//    color: #E18250;
//}
//.el-button.el-button--default.is-disabled{
//    background-color: #F7F7F7;
//    border-color: #F7F7F7;
//    color: #B3B3B3;
//}
//
//.el-button.el-button--primary {
//    color: #404040;
//}
//.el-button.el-button--primary:hover {
//    background-color: #EE7918;
//    border-color: #EE7918;
//    color: white;
//}
//.el-button.el-button--primary.is-disabled{
//    background-color: #FEEAC1;
//    border-color: #FEEAC1;
//    color: #B3B3B3;
//}
//
//.el-button.el-button--text {
//    color: #999999;
//}
//.el-button.el-button--text:hover {
//    color: #E18250;
//}




.el-button.table-action-button {
    padding: 5px 5px;
}
.el-button.table-action-button i {
    padding: 0;
}





.el-form-item--small.el-form-item {
    margin-bottom: 10px;
}
.el-form-item.el-form-item--small label.el-form-item__label {
    padding:0;
}


.card-stages{
    font-size: 0.9rem;
}
.card-section{
    font-size: 1.1rem;

    .el-table{
        font-size: 0.8rem;
    }
}

.option-no-label{
    color: #b2b2b2;
    font-style: italic;
}


body{
    margin:0;
}

.el-header, .el-menu, .menu-router-link,.el-menu-item, .el-submenu, .el-submenu__title, .el-submenu__icon-arrow {
    background-color: #145969 !important;
    color: white !important;
    font-weight: bold;
}

.el-header img{
    height:25px;
    padding-bottom: 7px !important;
}

.el-header button i{
    color: white;
    margin-top: 5px;
}
.timeline .negotiation.complete .negotiation-bottom {
    border-top: 2px solid $--color-primary !important;
}
.timeline .negotiation.complete .negotiation-bottom:before {
    background-color: $--color-primary !important;
}


.smz-card-name{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 14px;
}
.smz-table-name{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}
.smz-base-table {
    color: #141414 !important;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);

    th, td {
        padding: 5px 10px;
        border: 1px solid #b4b4b4;
        border-collapse: collapse;
    }

    th {
        //background-color: #f1f1f1;
        background-color: rgba($--color-primary, .1);
    }

    &-cell-gray {
        background-color: rgba($--color-primary, .1);
    }
}

.el-input__inner {
    border: 1px solid #636363;
}

.card-column-color-1{
    background-color: #D5DBE4 !important;
}
.card-column-color-2{
    background-color: #FEF2CB !important;
}
.card-column-color-3{
    background-color: #E2EFD8 !important;
}

.procedure-users-tree .el-tag {
    border-radius: 45px;
    color: $--color-text-regular !important;
}

.compensation-procedure-tree-agree-buttons .el-tag{
    border-radius: 45px;
    background-color: $--color-primary;
    color: white;
    font-weight: bold;
    margin-left: 20px;
}